<template>
  <div>

    <div class="flex gap-4">
      <h3 class="text-xl font-weight-bold">{{ layer }}</h3>
      <input
        type="file"
        @change="uploadLayer"
        ref="file"
        accept="image/png"
        :disabled="loadingCount > 0"
      >
    </div>
    <img
      v-if="timestamp"
      class="layer mt-2"
      :src="layerSource"
      alt="Layer"
    >
  </div>
</template>

<script>
export default {
  name: 'TemplateLayer',
  components: {},
  props: ['template', 'layer'],
  computed: {
    layerSource() {
      const uri = `/api/layers/${this.layer}.png?ts=${this.timestamp}`;
      return uri;
    },
  },
  data() {
    return {
      loadingCount: 0,
      timestamp: null,
    };
  },
  methods: {
    updateTimestamp() {
      this.timestamp = parseInt(new Date().getTime() / 1000, 10);
    },
    uploadLayer() {
      this.loadingCount++;
      const formData = new FormData();
      formData.append('image', this.$refs.file.files[0]);
      formData.append('layer', this.layer);
      this.$http
        .post('/layer_update')
        .send(formData)
        .then(() => {
          this.updateTimestamp();
        })
        .catch((err) => {
          this.$toast.error(`Failed to update layer: ${err.response.text}`);
        })
        .finally(() => {
          this.$refs.file.value = '';
          this.loadingCount--;
        });
    },
  },
  created() {
    this.updateTimestamp();
  },
};
</script>

<style lang="scss" scoped>
.layer {
  background-color: teal;
  border: 1px solid black;
  max-width: 800px;
}
</style>
